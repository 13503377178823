<template>
  <div class="animated fadeIn">
    <b-row v-if="showAddButton">
      <b-col>
        <b-button-group>
          <b-button
            variant="outline-dark"
            title="Create item"
            @click="addItem()"
          >
            <font-awesome-icon icon="file" /> Create
          </b-button>
        </b-button-group>
      </b-col>
    </b-row>

    <table-custom
      ref="dataTable"
      :name="`${$customTable.getCustomTableName($route.meta.module)}`"
      :loading="dataTable.isLoading"
      :data="dataTable.dataSet"
      :options="dataTable.options"
      @view-item="viewItem"
      @edit-item="editItem"
      @delete-item="deleteItem"
      @filter="onFilter"
      @inline-update="onInlineUpdate"
    >
      <div slot="afterFilter"></div>
    </table-custom>
  </div>
</template>

<script>
import TableCustom from "@/components/TableCustom";

export default {
  props: {
    accountId: {
      type: [Number, String],
      default: ""
    },
    showAddButton: {
      type: Boolean,
      default: true
    },
    columns: {
      type: Array,
      default: undefined
    }
  },
  name: "ItemsTable",
  components: {
    TableCustom
  },
  data: function() {
    return {
      rawData: {},
      appliedFilters: [],
      dataTable: {
        isLoading: false,
        dataSet: [],
        options: {
          columns: this.columns
            ? this.columns
            : [
                "ID",
                "Item Name",
                "Category",
                "Serial Number",
                "Last Order",
                "Active",
                "Errors",
                "In Transit",
                "Overdue"
              ],
          filterable: [],
          //editableColumns: ["Subject", "Invoice Date", "Assigned To"],
          //dropdownColumns: [{ name: "Assigned To", options: [] }],
          //footerHeadings: false,
          //perPage: 50,
          showActions: true
        }
      }
    };
  },
  computed: {},
  mounted() {
    this.fetchDropdowns();
  },
  methods: {
    fetchDropdowns() {},
    onFilter() {},
    async getData(payload) {
      let self = this;

      this.dataTable.isLoading = true;

      this.dataTable.dataSet = [];

      this.appliedFilters = payload;

      let method = "",
        url = "";

      method = "post";
      url = "inventory/items";

      return this.$api[method](url, payload)
        .then(response => {
          self.dataTable.isLoading = false;

          self.$emit("loaded", response.length);

          self.dataTable.dataSet = response;

          if (response.length === 0) return;
        })
        .catch(error => {
          console.log(error);
          self.dataTable.isLoading = false;
          self.$form.msgBoxOk("Error occured");
        });
    },
    addItem: function() {
      this.$router.push({
        name: "Inventory item submission",
        params: {
          action: "create"
        }
      });
    },
    viewItem: function(id) {
      this.$router.push({
        name: "Inventory item submission",
        params: {
          action: "view",
          id: id
        }
      });
    },
    editItem: function(id) {
      this.$router.push({
        name: "Inventory item submission",
        params: {
          action: "edit",
          id: id
        }
      });
    },
    deleteItem: async function(id) {
      let item = this.dataTable.dataSet.find(item => item.ID === id);

      let confirm = await this.$form.showConfirmation(
        `Item #${item["Item Name"]} will be deleted. Do you want to proceed?`
      );

      if (!confirm) return;

      let self = this;

      this.$api
        .delete(`inventory/item/${item.ID}`)
        .then(response => {
          self.$form.makeToastInfo(response.message);

          self.getData(self.appliedFilters);
        })
        .catch(response => {
          console.log(response);

          self.$form.makeToastError(response.message);
        });
    },

    onInlineUpdate() {
      console.log("ItemsTable.onInlineUpdate");
      /*
      const columnsMapping = [
        { alias: "Subject", dbName: "subject" },
        { alias: "Invoice Date", dbName: "invoice_date" },
        { alias: "Assigned To", dbName: "assigned_to" }
      ];

      let value = e.value;
      let dbName = columnsMapping.find(m => m.alias === e.column).dbName;

      this.updateField(e.id, dbName, value);
      */
    },

    updateField(id, field, value) {
      let self = this;
      let params = {};
      params["id"] = id;
      params[field] = value;

      this.$api
        .put(`inventory/order/${id}`, params)
        .then(response => {
          self.$form.makeToastInfo(`${field}: ${response.message}`);
        })
        .catch(response => {
          console.log(response);

          self.$form.makeToastError(`${field}: ${response.message}`);
        });
    }
  },
  watch: {}
};
</script>

<style scoped></style>
